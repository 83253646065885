import PropTypes from "prop-types";
import React, { useContext, useRef } from "react";
import { graphql } from "gatsby";
import { ThemeContext } from "../layouts";
import Blog from "../components/Blog";
import Hero from "../components/Hero";
import Seo from "../components/Seo";
import Subscribe from "../components/Forms/Subscribe";

const IndexPage = props => {
  const separator = useRef();

  const scrollToContent = e => {
    separator.current.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const {
    data: {
      posts: { edges: posts = [] },
      bgDesktop: {
        resize: { src: desktop }
      },
      bgTablet: {
        resize: { src: tablet }
      },
      bgMobile: {
        resize: { src: mobile }
      },
      site: {
        siteMetadata: { facebook }
      }
    }
  } = props;

  const backgrounds = {
    desktop,
    tablet,
    mobile
  };

  const theme = useContext(ThemeContext);

  return (
    <React.Fragment>
      <Hero scrollToContent={scrollToContent} backgrounds={backgrounds} theme={theme} />

      <hr ref={separator} />

      <br />
      <br />
      <br />

      <div>
        <Subscribe theme={theme} />
        <Blog posts={posts} theme={theme} />
      </div>

      <Seo facebook={facebook} />

      <style jsx>{`
        hr {
          margin: 0;
          border: 0;
        }
      `}</style>
    </React.Fragment>
  );
};

IndexPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default IndexPage;

//eslint-disable-next-line no-undef
export const query = graphql`
  query IndexQuery {
    posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "//posts/[0-9]+.*--/" } }
      sort: { fields: [fields___prefix], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            prefix
          }
          frontmatter {
            title
            category
            author
            cover {
              children {
                ... on ImageSharp {
                  fluid(maxWidth: 800, maxHeight: 360) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        facebook {
          appId
        }
      }
    }
    bgDesktop: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 1200, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgTablet: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 800, height: 1100, quality: 90, cropFocus: CENTER) {
        src
      }
    }
    bgMobile: imageSharp(fluid: { originalName: { regex: "/hero-background/" } }) {
      resize(width: 450, height: 850, quality: 90, cropFocus: CENTER) {
        src
      }
    }
  }
`;

//hero-background
